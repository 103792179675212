

import AbpBase from "@/lib/abpbase";
import Campana from "../../../store/entities/OZONE/campana";
import SelectCampana from "../campana/selectCampana.vue";
import { Component } from "vue-property-decorator";
import Modal from "../../../components/Modal.vue";
import BaseButton from "@/components/BaseButton.vue";
import Marca from "../marca/marca.vue";
import PeopleHeader from "@/components/People/people-header.vue";
import Charlie from "@/store/entities/OZONE/charlie";

@Component({
  components: { SelectCampana, Modal, BaseButton, Marca, PeopleHeader }
  }
)
export default class ImportarCharlie extends AbpBase {
  plantillasIn = [
    //{label: "Genérica", valor: 0},
    {label: "Stellanties", valor: 1},
    {label: "Cumpleaños", valor: 2}
  ]
  muestraFin = false;
  muestraInicio = false;
  datosCharlie = new Charlie();
  form: {
    campana: Campana;
    plantillaIn: number;
    fichero: File;
  } = {
    campana: null,
    plantillaIn: null,
    fichero: null
  };

  async created() {
    this.form.campana = null;
    this.muestraInicio = true;
  }

  nuevoFichero() {
    this.muestraInicio = true;
    this.muestraFin = false;
    this.form.campana = null;
    this.form.plantillaIn = null;
    this.form.fichero = null;
  }
  
  readFile(file) {
    const reader = new FileReader();
    if (file.name.includes(".txt")) {
      reader.onload = (res) => {
        this.datosCharlie.ficheroDatos = res.target.result.toString();
        this.datosCharlie.nomFichOrigen = file.name;   
      };
      reader.readAsText(file);
    }
  }
    
  process() {
    debugger;
    this.datosCharlie.campId = this.form.campana.id;
    this.datosCharlie.nomCamp = this.form.campana.nombre;
    this.datosCharlie.ekonCamp = this.form.campana.axapta;
    this.datosCharlie.plantillaInId = this.form.plantillaIn; 

    this.$store.dispatch({
      type: "charlie/postProcesaDatosCharlie",
      data: this.datosCharlie,
    });
    
    this.muestraInicio = false;
    this.muestraFin = true;
    this.$message.success("Se ha realizado el envío correctamente", 5);
  }
}
