import CharlieRegistro from "./charlieRegistro";

export default class Charlie {
    plantillaInId: number;
    plantillaOutId: number;
    nomCliente: string;
    ficheroDatos: string;
    registrosFichero: Array<CharlieRegistro>;
    nomFichOrigen: string;
    origenFichero: string;
    observaciones: string;
    mensajeriaId: number;
    campId: number;
    ekonCamp: string;
    nomCamp: string;
    enviosId: Array<number>;
    participacionesId: Array<number>;
}